<template>
  <v-container >
    <!-- <PageTitleSearch
      :page-title="!$language ? 'Tests' : 'পরীক্ষা'"
      :placeholder="
        !$language ? 'Search By Title' : 'পরীক্ষার শিরোনাম দিয়ে সার্চ করো'
      "
      @searchElenent="searchExam"
    ></PageTitleSearch> -->
    <div v-if="renderHtml" class="mb-2" style="font-size: 20px">
        Tests
    </div>
    <v-container>
      <v-row>
        <v-col v-if="!renderHtml">
          <div id="loading-wrapper">
            <div id="loading-text">LOADING</div>
            <div id="loading-content"></div>
          </div>
        </v-col>
        <!-- <v-col v-else-if="noExamCondition">
          <no-content-component :message="noExamMessage"></no-content-component>
        </v-col> -->
        <v-col v-else :class="classes.container" class="bg-white rounded-lg"  style="min-height: 75vh;">
          <course-sections 
            :sections="sections"
            @onSelect="selectSection"
          />
          <div
            style="max-height: 60vh"
            class="mt-2 overflow-x-hidden overflow-y-auto"
          >
            <course-organization-expansion-panel
              :isNote="false"
              :isTest="true"
              :selected="selected"
              :course-id="courseId"
            />
              <!-- :owner-id="ownerId" -->
            </div>  
        </v-col>
        <!-- <v-col v-else v-for="(exam, i) in filterExams" :key="i" :cols="colsNum">
          <m-exam-list-item
            :exam="filterExams[i]"
            :batch_id="batch_id"
          ></m-exam-list-item>
        </v-col> -->
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import _ from "lodash";
// import examService from "#ef/exam/services/ExamService";
// import PageTitleSearch from "@ef/global/components/PageTitleSearch.vue";
// import MExamListItem from "#ef/exam/components/MExamListItem";
// import NoContentComponent from "/global/components/NoContentComponent";
import { CourseDesignUtils } from "#ecf/course-design/mixins";
import CourseOrganizationExpansionPanel from "#ecf/course-design/components/CourseOrganization/CourseOrganizationExpansionPanel.vue";
import CourseSections from "#ecf/course-design/components/CourseOrganization/CourseSections.vue";
export default {
  name: "MExam",
  // PageTitleSearch
  // MExamListItem
  // NoContentComponent
  components: { CourseOrganizationExpansionPanel,CourseSections },
  mixins: [CourseDesignUtils],
  data() {
    return {
      link_dialog_visible: false,
      saving: false,
      shareExamPanel: false,
      examToShare: undefined,
      share_idx: undefined,
      sharing: {},
      unsharing: {},
      examDeleteDialog: false,
      examToDelete: undefined,
      deleting: false,
      examsData: [],
      exams: [],
      examHistory: {},
      searchInput: "",
      filterExams: [],
      renderHtml: false,
      ///
      selected: undefined,
      courseId: undefined,
    };
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    colsNum() {
      return this.currentWidth < 800
        ? 12
        : this.currentWidth < 1280
        ? 12
        : this.currentWidth < 1920
        ? 6
        : 4;
    },
    examRowCols() {
      return this.shareExamPanel ? 8 : 9;
    },
    share_panel_key() {
      if (!this.examToShare) {
        return "undefined";
      } else {
        return (
          this.examToShare.object_id +
          "#" +
          this.examToShare.batches.map(b => b.shared + "").join("#")
        );
      }
    },
    // noExamCondition() {
    //   return this.exams === undefined || this.exams.length === 0;
    // },
    noExamMessage() {
      return !this.$language
        ? "You currently have no exams"
        : "বর্তমানে আপনার কোনো পরীক্ষা নেই";
    },
    sections() {
        return this.$store.state.studentCourseDesignDashboard.courseSections[
          this.$ielts_course_id
        ];
    },
    classes() {
      return {
        container: `pa-${this.breakPointValues(3, 3, 6, 6)}`,
        slideItem: {
          outer: {
            true: "primary white--text",
            false: "bg-white border border-solid border-primary primary--text"
          },
          inner: {
            true: "white primary--text",
            false: "primary white--text"
          }
        }
      };
    },
  },

  watch: {
    searchInput: {
      handler(newValue) {
        this.filterExams = this.exams.filter(exam =>
          exam.exam_title.toLowerCase().match(newValue.toLowerCase())
        );
      }
    }
  },

  async created() {
    // this.fetchSharedExams();
    await this.retriveStudentsCourseDesign()
  },

  methods: {
    searchExam(searchInput) {
      this.searchInput = searchInput;
    },

    // async fetchSharedExams() {
    //   try {
    //     this.renderHtml = false;
    //     let content = await examService.list_exams_with_all_informations(
    //       this.$ielts_batch_id
    //     );
    //     this.exams = content.exams;
    //     this.filterExams = this.exams;
    //   } catch (e) {
    //     this.$root.$emit("alert", [undefined, e.message]);
    //   } finally {
    //     this.renderHtml = true;
    //   }
    // },
    async retriveStudentsCourseDesign() {
      try {
        this.renderHtml = false;
        this.courseId = this.$ielts_course_id;
        console.log('course',this.courseId)
        let studentId = this.$store.state.user.user.user_id;
        console.log(studentId)
        await this.$store.dispatch(
          "studentCourseDesignDashboard/getCourseDesign",
          {
            courseId: this.courseId,
            studentId
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderHtml = true;
      }
    },
    selectSection(value) {
      this.selected = value
    }
  }
};
</script>
